import React from 'react';
import { Modal } from 'react-bootstrap';
import { UnTracked } from './ProductiveDetails';

interface PropsObject {
    show: boolean,
    handleModalClose: () => void,
    unTrackedUsers: UnTracked[]
}

const NotTrackedList = (props: PropsObject) => {
    const { show, handleModalClose, unTrackedUsers } = props
    return (
        <Modal
            show={show}
            onHide={() => handleModalClose()}
            backdrop='static'
            keyboard={false}
            centered
            size='lg'
            dialogClassName='not-tracked-modal'
        >
            <Modal.Header closeButton>
                <Modal.Title>Haven’t Tracked Yet <span>{unTrackedUsers?.length} Peoples</span></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Designation</th>
                            <th>Reason</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {unTrackedUsers?.map((user, ind) => (
                            <tr key={ind}>
                                <td>{user?.employee_name}</td>
                                <td>{user?.designation}</td>
                                <td>{user?.reason}</td>
                                <td></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Modal.Body>
        </Modal>
    );
};

export default NotTrackedList;