import React, { useEffect, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { showToast } from "../../features/Toaster/toastslice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RolePermission, FeatureAccess } from "../../pages/ManagerList";
import {
  commonPostservice,
  commonGetService,
  commonPutService,
} from "../../utils/properties";
import { Select } from "antd";

interface DepartmentOptions {
  label: string;
  value: string;
}

interface RoleData {
  id?: string;
  role: string;
  description: string;
  departments?: string[];
  created_date?: string;
  sr_no?: string;
  modified_date?: string;
  role_enabled?: boolean;
  role_permissions: RolesPermission[];
}

interface RolesPermission {
  page_name: string;
  status?: boolean;
  features: { id: string; name: string; status: boolean }[]
}


const AddRoles = () => {
  const Navigate = useNavigate();
  const location = useLocation();
  let path = location.pathname.split("/");
  let params = new URLSearchParams(location.search);
  let roleKey = params.get("roleKey");

  const dispatch = useAppDispatch();

  const [rolesData, setRolesData] = useState<RoleData>({
    role: "",
    description: "",
    departments: [],
    role_enabled: true,
    role_permissions: [],
  });
  const [isSave, setIsSave] = useState<boolean>(false);
  const [textFieldError, setTextFieldError] = useState<boolean>(false);
  const [spinner, setSpinner] = useState(true);
  const [deptOptions, setDeptOptions] = useState<DepartmentOptions[]>([]);

  useEffect(() => {
    getRoleDetails();
  }, [roleKey]);

  const getRoleDetails = () => {
    commonGetService(`/api/1.0/role/departments`).then((res) => {
      if (res.status === 200) {
        setDeptOptions(res.data);
      }
      else {
        dispatch(
          showToast({
            message: "Something went wrong in API.",
            status: "retry",
            visible: true,
          })
        );
      }
    });

    if (path[1] === "addroles") {
      commonGetService(`/api/1.0/feature/all`).then((res) => {
        if (res.status === 200) {
          const groupedData = Object.values(
            res.data.reduce((acc: { [key: string]: RolesPermission }, { id, name, status, page_name }: { id: string, name: string, status: any, page_name: string }) => {
              if (!acc[page_name]) {
                acc[page_name] = { page_name, status: false, features: [] };
              }
              acc[page_name].features.push({ id, name, status });
              return acc;
            }, {})
          );
          setRolesData((prevState) => ({
            ...prevState,
            role_permissions: groupedData as RolesPermission[],
          }));
        }
        else {
          dispatch(
            showToast({
              message: "Something went wrong in API.",
              status: "retry",
              visible: true,
            })
          );
        }
      });

      setSpinner(false);
    } else {
      commonGetService(`/api/1.0/role/id?id=${roleKey}`).then((res) => {
        if (res.status === 200) {
          let rawdata = {
            id: res.data.id,
            role: res.data.roleName,
            description: res.data.description,
            departments: res.data.departmentIds,

            role_enabled: res.data.role_enabled,
            role_permissions: Object.keys(res.data.features).map(category => ({
              category: category,
              status: res.data.features[category].status || false,
              features: res.data.features[category].features.map((feature: { id: string; name: string; status: boolean }) => ({
                id: feature.id,
                name: feature.name,
                status: feature.status || false,
                page_name: res.data.features[category]
              }))
            })),
          }
          setRolesData({
            ...rawdata,
            role_permissions: Object.keys(res.data.features).map(category => ({
              page_name: category,
              category: category,
              status: res.data.features[category].status || false,
              features: res.data.features[category].features.map((feature: { id: string; name: string; status: boolean }) => ({
                id: feature.id,
                name: feature.name,
                status: feature.status || false,
              }))
            })),
          });
          setSpinner(false);
        } else {
          dispatch(
            showToast({
              message: "Something went wrong",
              status: "retry",
              visible: true,
            })
          );
          setSpinner(false);
        }
      });
    }
  };

  const handlePageAccess = (page_name: string, status: boolean) => {
    const accessData = rolesData?.role_permissions?.map((item) => {
      const updatedItem = { ...item };

      if (updatedItem.page_name === page_name) {
        updatedItem.status = status;

        if (!status) {
          updatedItem.features = updatedItem.features?.map((feature) => ({
            ...feature,
            status: false,
          }));
        }
      }

      return updatedItem;
    });
    setRolesData((prevState) => {
      return {
        ...prevState,
        role_permissions: accessData,
      };
    })
  };

  const FeaturesAllowed = (category_name: string, feature_name: string, feature_access: boolean) => {

    let newData = rolesData?.role_permissions?.map((item) => {
      if (item.page_name === category_name && item.status) {
        item.features = item.features?.map((cgy) => {
          if (cgy.id === feature_name) {
            cgy.status = feature_access
          }
          return cgy;
        })
      }
      return item;
    })

    setRolesData((prevState) => {
      return {
        ...prevState,
        role_permissions: newData,
      };
    });

  }
  const handleSave = () => {
    if (handleValidate()) {
      setIsSave(true);

      if (path[1] === "addroles") {
        const feature_ids = rolesData?.role_permissions?.flatMap((res) =>
          res.features?.filter((feature) => feature.status === true).map((feature) => feature.id) || []
        );
        let payload = {
          role_name: rolesData?.role,
          description: rolesData?.description,
          department_ids: rolesData?.departments,
          feature_id: feature_ids
        }

        commonPostservice(`/api/1.0/role/create`, payload).then((res) => {
          setIsSave(false);
          if (res.status === 200) {
            dispatch(
              showToast({
                message: "The role has been created successfully.",
                status: "success",
                visible: true,
              })
            );
            Navigate(`/roles`, { state: { reload: true } });
          }
        })
      } else if (path[1] === "editroles") {
        const features = rolesData.role_permissions.flatMap(permission =>
          permission.features.map(feature => ({
            id: feature.id,
            status: feature.status
          }))
        );

        // Extract unique page names and their statuses
        const pageNames = rolesData.role_permissions.map(permission => ({
          page_name: permission.page_name,
          status: permission.status
        }));
        let payload = {
          role_name: rolesData.role || "",
          description: rolesData.description || "",
          department_ids: rolesData.departments || [],
          features: features,
          page_names: pageNames
        }

        commonPutService(`/api/1.0/role/update?id=${roleKey}`, payload).then((res) => {
          setIsSave(false);
          if (res.status === 200) {
            dispatch(
              showToast({
                message: "The role has been updated successfully.",
                status: "success",
                visible: true,
              })
            );
            Navigate(`/roles`, { state: { reload: true } });
          }
        })


      }
    };
  }
  const handleValidate = () => {
    const selectedPermissions = rolesData?.role_permissions?.filter((res) => res.status);


    if (!rolesData?.role || !rolesData?.departments?.length || !rolesData?.description) {

      setTextFieldError(true);
      dispatch(
        showToast({
          message: "Fill required fields",
          status: "retry",
          visible: true,
        })
      );
      return false
    } if (selectedPermissions?.length === 0) {
      dispatch(
        showToast({
          message: "Add atleast Permission for one page",
          status: "retry",
          visible: true
        })
      )
      return false
    }
    selectedPermissions?.forEach((permission) => {

      if (permission.features?.every((feature) => !feature.status)) {
        dispatch(
          showToast({
            message: "Add atleast one permission",
            status: "retry",
            visible: true
          })
        )
        return false
      }
    })
    return true;
  };

  const handleCancel = () => {
    Navigate("/roles", { state: { reload: true } });
  };
  const handleChange = (value: string[]) => {
    setRolesData({ ...rolesData, departments: value });
    setTextFieldError(false);
  };

  return (
    <div className={`add-roles-wrapper ${spinner && "spinner-wrapper"} `}>
      {!spinner ? (
        <>
          <h4>
            {path[1] === "editroles"
              ? "Edit Roles"
              : path[1] === "viewroles"
                ? "View Roles"
                : "Add Roles"}
          </h4>
          {path[1] !== "viewroles" && <span>Set Role Permissions</span>}
          <Form>
            <Form.Group className="mb-2-5">
              <Form.Label>Role Name</Form.Label>
              <Form.Control
                className={
                  textFieldError && rolesData?.role === "" ? "input-error" : ""
                }
                type="text"
                placeholder="Enter Role Name"
                value={rolesData?.role}
                disabled={path[1] === "viewroles"}
                onChange={(e) => {
                  setRolesData({ ...rolesData, role: e.target.value });
                  setTextFieldError(false);
                }}
              />
            </Form.Group>

            <Form.Group className="mb-2-5">
              <Form.Label>Department</Form.Label>
              <Select
                mode="multiple"
                className={
                  textFieldError && rolesData?.departments?.length === 0
                    ? "input-error"
                    : ""
                }
                value={rolesData?.departments}
                placeholder="Add Department"
                style={{ flex: 1 }}
                disabled={path[1] === "viewroles"}
                options={deptOptions}
                suffixIcon={<></>}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-2-5 w-100">
              <Form.Label>Description</Form.Label>
              <Form.Control
                className={
                  textFieldError && rolesData?.description === ""
                    ? "input-error"
                    : ""
                }
                type="text"
                placeholder="Describe role including key responsibilities"
                value={rolesData?.description}
                disabled={path[1] === "viewroles"}
                onChange={(e) => {
                  setRolesData({ ...rolesData, description: e.target.value });
                  setTextFieldError(false);
                }}
              />
            </Form.Group>
          </Form>
          <h4 className="table-headings-role">Role Permissions</h4>
          {rolesData.role_permissions.map((category, categoryIndex) => (
            <div className="table-wrapper" key={category?.page_name}>
              <table>
                <thead>
                  <tr>
                    <th className="text-capitalize">{category.page_name?.replace("_", " ")}</th>
                    <th></th>
                    <th>
                      <Form.Check
                        type="switch"
                        disabled={path[1] === "viewroles"}
                        checked={category?.status}
                        onChange={(e) => {

                          handlePageAccess(category?.page_name, e.target.checked);

                        }}
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {category?.features
                    .reduce((acc: { id: string; name: string; status: boolean }[][], feature, index) => {
                      const rowIndex = Math.floor(index / 3);
                      if (!acc[rowIndex]) acc[rowIndex] = [];
                      acc[rowIndex].push(feature);
                      return acc;
                    }, [])
                    .map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {row.map((feature, featureIndex) => (
                          <td key={featureIndex}>
                            <Form.Check
                              type="checkbox"
                              checked={feature.status}
                              onChange={(e) => FeaturesAllowed(category.page_name, feature.id, e.target.checked)}
                              label={feature.name}
                              disabled={path[1] === "viewroles"}

                            />
                          </td>
                        ))}
                        {Array.from({ length: 3 - row.length }).map((_, placeholderIndex) => (
                          <td key={`placeholder-${placeholderIndex}`}><Form.Check
                            type="checkbox"
                            style={{ visibility: "hidden" }}
                            disabled={true}
                          /></td>
                        ))}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          ))}

          {path[1] !== "viewroles" && (
            <div className="d-flex justify-content-end w-100">
              <button className="cancel_button" onClick={handleCancel}>
                Cancel
              </button>
              <button
                className="save_button"
                onClick={handleSave}
                disabled={isSave}
              >
                {path[1] !== "editroles" ? "Save" : "Save Changes"}
              </button>
            </div>
          )}
        </>
      ) : (
        <div className="d-flex justify-content-center align-items-center h-100 crt-loader h-20">
          <Spinner animation="border" />
        </div>
      )}
    </div>
  );
};

export default AddRoles;
