import React from 'react'
import { Col, Image, Modal, Row } from 'react-bootstrap'
import { UnTracked } from './ProductiveDetails';
interface LiveEmployeePopupProps {
    modalShow: boolean;
    handleModalClose: () => void;
    liveEmployeeData: UnTracked[]
}
const LiveEmployeePopup = ({ modalShow, handleModalClose, liveEmployeeData }: LiveEmployeePopupProps) => {
    return (
        <Modal show={modalShow} onHide={handleModalClose} centered size='lg' className='work-employ-list'>
            <Modal.Header closeButton>
                <Modal.Title>Currently working employees  <span className='emp-count'>{liveEmployeeData.length} &nbsp;{liveEmployeeData.length === 1 ? "People" : "peoples"}</span></Modal.Title>
            </Modal.Header>
            <Modal.Body className={`${liveEmployeeData.length > 14 ? 'modal-body-scroll' : ''}`}>

                <Row>
                    {liveEmployeeData.length ? liveEmployeeData?.map((employee, index) => (
                        <Col key={index} lg={6}>
                            <div className="flex-start align-start">
                                <div
                                    className={
                                        employee?.image
                                            ? "employee-live-pic-container"
                                            : "employee-live-pic-container fill-color-avatar"
                                    }
                                >
                                    {employee?.image ? (
                                        <img src={employee?.image} alt={employee?.employee_name} />
                                    ) : (
                                        <span className='avatar-letter'>{employee?.employee_name.charAt(0)}</span>
                                    )}
                                </div>
                                <div className="employee-info">
                                    <p className="emp-name ellipsis" title={employee?.employee_name}>
                                        {employee?.employee_name}
                                    </p>
                                    <p className="designation ellipsis">{employee?.designation}</p>
                                </div>
                            </div>
                        </Col>
                    ))
                        :
                        (<div className="d-flex flex-row  w-100 no-data-row">
                            <div className="no-data-image-wrapper no-user-image">
                                <Image src="/images/dashboard/user_icon.png" alt="No Data Found" />
                            </div>
                            <p>No users to show</p>
                        </div>)
                    }
                </Row>

            </Modal.Body>

        </Modal>
    )
}

export default LiveEmployeePopup