import React, { useState, useEffect } from "react";
import {
  Container,
  Image,
  Row,
  Col,
  Dropdown,
  DropdownButton,
  Offcanvas,
} from "react-bootstrap";
import "../styles/navbar.scss";
import ProfileBadge from "./CustomComponents/ProfileBadge/ProfileBadge";
import NotificationDropdown from "./CustomComponents/NotificationBell/NotificationDropdown";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../app/hooks";
import {
  setDate,
  setDeviceID,
  setFilterChosen,
  setFilterType,
  setDepartment,
  setOrganization,
} from "../features/date/dashboardSlice";
import { setModalShow } from "../features/addManager/addManagerSlice";
import { setManagerDropShow } from "../features/addManager/addManagerSlice";

interface layoutProps {
  children: React.ReactNode;
}
export interface userDetails {
  Name: string;
  picture: string | null;
  employee_id: string;
  role: string;
}

const NavbarLayout = (props: layoutProps) => {
  const Navigate = useNavigate();
  const location = useLocation();
  const addManager = useAppSelector((state) => state.addManager);
  const dispatch = useAppDispatch();

  const [navShow, setNavShow] = useState<boolean>(false);
  const [projectHover, setProjectHover] = useState<boolean>(false);
  const [manualTimeHover, setManualTimeHover] = useState<boolean>(false);
  const [addMgrHover, setMgrHover] = useState<boolean>(false);
  const [reportHover, setReportHover] = useState<boolean>(false);
  const [moreHover, setMoreHover] = useState<boolean>(false);
  const [settingHover, setSettingHover] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<string>("dashboard");
  const [dropShow, setDropShow] = useState<boolean>(false);
  // const [managerDropShow, setManagerDropShow] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = () => {
      if (addManager?.managerDropShow) {
        dispatch(setManagerDropShow(false))
      }
    };
    // Add scroll event listener
    window.addEventListener("wheel", handleScroll);
    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("wheel", handleScroll);
    };
  }, [addManager?.managerDropShow]);

  const Userdetails: userDetails = {
    Name: "John Doe ",
    picture: "/images/dashboard/profile_pic.svg",
    employee_id: "SW-123456",
    role: "Admin",
  };

  const handleNavClose = () => {
    setNavShow(false);
  };

  const handleTab = (tab: string) => {
    setSelectedTab(tab);
    let today = new Date();
    dispatch(setDeviceID(""));
    dispatch(setDate({ startDate: today, endDate: today }));
    dispatch(setFilterChosen("today"));
    dispatch(setFilterType("organization"));
    dispatch(setDepartment(""));
    dispatch(setOrganization("All Organizations"));

    if (tab === "dashboard") {
      Navigate("/dashboard");
    } else if (tab === "screenshots") {
      Navigate("/screenshots");
    } else if (tab === "setting") {
      Navigate("/settings");

    } else if (tab === "managerList") {
      Navigate("/managerList");
    } else if (tab === "roles") {
      Navigate("/roles");
    }
    else if (tab === "reports") {
      Navigate("/reports")
    }
  };
  return (
    <Container fluid className="w-100 m-0 p-0">
      <Row
        className="nav-bar justify-content-between align-items-center m-0 nav-menu-max position-sticky top-0 z-3"
      >
        <Col
          className="p-0 menu-btn cursor-pointer"
          md="auto"
          onClick={() => setNavShow(true)}
        >
          <Image src="/images/dashboard/menu_button.svg" alt="Menu button" />
        </Col>
        <Col className="p-0" lg={2} md>
          <Image
            src="/images/dashboard/timelyze_new_logo_tab.svg"
            className="brand-logo"
          />
        </Col>
        <Col
          className="d-flex align-items-center justify-content-center nav-menu p-0 "
          lg={7}
        >
          <div
            className={`d-flex align-items-center cursor-pointer ${location.pathname.includes("dashboard") ? "active-tab" : ""
              }`}
            onClick={() => handleTab("dashboard")}
          >
            <div>
              <Image
                src="/images/dashboard/dashboard_icon.svg"
                alt="Dashboard"
              />
            </div>
            <p>Dashboard</p>
          </div>
          {/* <div
            className={`d-flex align-items-center cursor-pointer ${location.pathname.includes("screenshots") ? "active-tab" : ""
              }`}
            onClick={() => handleTab("screenshots")}
          >
            <div>
              <Image
                src="/images/dashboard/dashboard_icon.svg"
                alt="screenshots"
              />
            </div>
            <p>Screenshots</p>
          </div> */}
          <div
            className={`d-flex align-items-center cursor-pointer ${location.pathname.includes("projects") ? "active-tab" : ""
              }`}
            onClick={() => handleTab("projects")}
          >
            {/* <div> */}
            <Image src="/images/dashboard/project_icon.svg" alt="Projects" />
            {/* </div> */}
            <p>Projects</p>
          </div>
          {/* <div
            className={`d-flex align-items-center cursor-pointer ${location.pathname.includes("manualTime") ? "active-tab" : " "
              }`}
            onClick={() => handleTab("manualTime")}
          >
            <div>
              <Image
                src="/images/dashboard/clock_icon.svg"
                alt="Manural Time"
              />
            </div>
            <p>Manual Time</p>
          </div> */}
          {/* <div
            className={`d-flex align-items-center cursor-pointer ${location.pathname.includes("addManager") ? "active-tab" : " "
              }`}
            onClick={() => handleTab("addManager")}
          >
            <div>
              <Image src="/images/dashboard/add_icon.svg" alt="Add Manager" />
            </div>
            <p>Add Manager</p>
          </div> */}
          <div
            className={`drop-mngr d-flex align-items-center ${location.pathname.includes("managerList") ? "active-tab" : " "
              }`}
            onClick={() => { dispatch(setManagerDropShow(!addManager?.managerDropShow)) }}
          >
            <DropdownButton
              id="dropdown"
              title="Managers"
              show={addManager?.managerDropShow}
              onToggle={() =>
                dispatch(setManagerDropShow(!addManager?.managerDropShow))
              }
            >
              <div>
                <Dropdown.Item onClick={() => dispatch(setModalShow(true))}>
                  Add Manager
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    handleTab("managerList");
                  }}
                >
                  Manager List
                </Dropdown.Item>
              </div>
            </DropdownButton>
            <div>
              <Image
                src={`/images/dashboard/${!addManager?.managerDropShow
                  ? "more_down_arrow"
                  : "more_up_arrow"
                  }.svg`}
                alt="Down Arrow"
                className="cursor-pointer ms-2"
              />
            </div>
          </div>
          <div
            className={`d-flex align-items-center cursor-pointer ${location.pathname.includes("reports") ? "active-tab" : " "
              }`}
            onClick={() => handleTab("reports")}
          >
            <div>
              <Image src="/images/dashboard/report_icon.svg" alt="Reports" />
            </div>
            <p>Reports</p>
          </div>
          <div
            className={`d-flex align-items-center cursor-pointer ${location.pathname.includes("screenshots") ? "active-tab" : ""
              }`}
            onClick={() => handleTab("screenshots")}
          >
            <div>
              <Image
                src="/images/dashboard/screenshot_logo.svg"
                alt="screenshots"
              />
            </div>
            <p>Screenshots</p>
          </div>
          <div className="drop-more d-flex align-items-center">
            <DropdownButton
              id="dropdown"
              title="More"
              onToggle={() => setDropShow(!dropShow)}
              show={dropShow}
            >
              {/* <Dropdown.Item>Front end team</Dropdown.Item>
              <Dropdown.Item>Back end team</Dropdown.Item>
              <Dropdown.Item>Testing team</Dropdown.Item> */}
              <Dropdown.Item onClick={() => handleTab("roles")}>Roles</Dropdown.Item>
            </DropdownButton>
            <div>
              <Image
                src={`/images/dashboard/${dropShow ? "more_down_arrow" : "more_up_arrow"
                  }.svg`}
                alt="Down Arrow"
                className="cursor-pointer ms-2"
              />
            </div>
          </div>
        </Col>
        <Col className="p-0 d-flex align-items-center nav-search" md lg={3}>
          <div className="ms-auto">
            <Image
              src="/images/dashboard/search_icon.svg"
              alt="Search"
              className="search-icon"
            />
          </div>
          {/* <div className="note-box">
            <Image
              src="/images/dashboard/notification_icon.svg"
              alt="Notification"
              className="not-icon"
            />
            <span className="d-inline-block">12</span>
          </div> */}
          <NotificationDropdown />
          <div className="split-pipe"></div>
          <div className="setting" onClick={() => handleTab("setting")}>
            <Image src="/images/dashboard/setting_icon.svg" alt="Setting" />
          </div>

          <ProfileBadge userDetails={Userdetails} />
        </Col>
      </Row>
      <Offcanvas
        placement="start"
        show={navShow}
        onHide={() => handleNavClose()}
        className="side-menu"
      >
        <Offcanvas.Header className="justify-content-between">
          <Offcanvas.Title>
            <Image
              src="/images/dashboard/timelyze_logo.svg"
              className="brand-logo"
            />
          </Offcanvas.Title>
          <Image
            src="/images/dashboard/side_menu_close.svg"
            className="side-close cursor-pointer"
            onClick={() => handleNavClose()}
          />
        </Offcanvas.Header>
        <Offcanvas.Body className="side-menu-body p-0 d-flex flex-column">
          <div className="d-flex flex-column">
            <div
              className={`d-flex flex-row menu-title-cont ${selectedTab === "dashboard" ? "active-tab" : ""
                }`}
              onClick={() => handleTab("dashboard")}
            >
              <div>
                <Image
                  src="/images/dashboard/dashboard_icon.svg"
                  alt="Dashboard"
                />
              </div>
              <p>Dashboard</p>
            </div>
            <div
              className={`d-flex flex-row menu-title-cont ${selectedTab === "screenshots" ? "active-tab" : ""
                }`}
              onClick={() => handleTab("screenshots")}
            >
              <div>
                <Image
                  src="/images/dashboard/dashboard_icon.svg"
                  alt="screenshots"
                />
              </div>
              <p>Screenshots</p>
            </div>
            <div
              className={`d-flex flex-row menu-title-cont ${selectedTab === "projects" ? "active-tab" : ""
                }`}
              onMouseEnter={() => setProjectHover(true)}
              onMouseLeave={() => setProjectHover(false)}
              onClick={() => handleTab("projects")}
            >
              <div>
                <Image
                  src={
                    projectHover || selectedTab === "projects"
                      ? "/images/dashboard/project_icon.svg"
                      : "/images/dashboard/fade_project_icon.svg"
                  }
                  alt="Projects"
                />
              </div>
              <p>Projects</p>
            </div>
            <div
              className={`d-flex flex-row menu-title-cont ${selectedTab === "manualTime" ? "active-tab" : ""
                }`}
              onMouseEnter={() => setManualTimeHover(true)}
              onMouseLeave={() => setManualTimeHover(false)}
              onClick={() => handleTab("manualTime")}
            >
              <div>
                <Image
                  src={
                    manualTimeHover || selectedTab === "manualTime"
                      ? "/images/dashboard/clock_icon.svg"
                      : "/images/dashboard/fade_clock_icon.svg"
                  }
                  alt="Manural Time"
                />
              </div>
              <p>Manual Time</p>
            </div>
            <div
              className={`d-flex flex-row menu-title-cont ${selectedTab === "addManager" ? "active-tab" : ""
                }`}
              onMouseEnter={() => setMgrHover(true)}
              onMouseLeave={() => setMgrHover(false)}
              onClick={() => handleTab("addManager")}
            >
              <div>
                <Image
                  src={
                    addMgrHover || selectedTab === "addManager"
                      ? "/images/dashboard/add_icon.svg"
                      : "/images/dashboard/fade_add_icon.svg"
                  }
                  alt="Add Manager"
                />
              </div>
              <p>Add Manager</p>
            </div>
            <div
              className={`d-flex flex-row menu-title-cont ${selectedTab === "reports" ? "active-tab" : ""
                }`}
              onMouseEnter={() => setReportHover(true)}
              onMouseLeave={() => setReportHover(false)}
              onClick={() => handleTab("reports")}
            >
              <div>
                <Image
                  src={
                    reportHover || selectedTab === "reports"
                      ? "/images/dashboard/report_icon.svg"
                      : "/images/dashboard/fade_report_icon.svg"
                  }
                  alt="Reports"
                />
              </div>
              <p>Reports</p>
            </div>
          </div>
          <div className="d-flex flex-column mt-auto">
            <div
              className={`d-flex flex-row menu-title-cont ${selectedTab === "settings" ? "active-tab" : ""
                }`}
              onMouseEnter={() => setSettingHover(true)}
              onMouseLeave={() => setSettingHover(false)}
              onClick={() => handleTab("setting")}
            >
              <div>
                <Image
                  src={
                    settingHover || selectedTab === "settings"
                      ? "/images/dashboard/setting_icon.svg"
                      : "/images/dashboard/fade_setting_icon.svg"
                  }
                  alt="Setting"
                />
              </div>
              <p>Settings</p>
            </div>
            <div
              className={`d-flex flex-row menu-title-cont ${selectedTab === "logout" ? "active-tab" : ""
                }`}
              onClick={() => handleTab("logout")}
            >
              <div>
                <Image
                  src="/images/dashboard/fade_logout_icon.svg"
                  alt="Add Manager"
                />
              </div>
              <p>Logout</p>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      <>{props.children}</>
    </Container>
  );
};

export default NavbarLayout;
