import React from "react";
import { Routes, Route } from "react-router";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Screenshots from "./pages/Screenshots";
// import Timelinecharts from "./components/TimelineCharts/Timelinecharts";
import Timelines from "./components/Dashboard/Timelines";
import ScrollToTop from "./components/ScrollToTop";
import Roles from "./pages/roles";
import ManagerList from "./pages/ManagerList";
import AddRoles from "./components/Roles/AddRoles";
import AddManager from "./components/AddManager/AddManager";
import { useAppSelector } from "./app/hooks";
import ToastContainer from "./components/CustomComponents/Toaster/ToastContainer";
import Settings from "./pages/Settings";
import Reports from "./pages/Reports";

function App() {
  const addManager = useAppSelector((state) => state.addManager);

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/screenshots" element={<Screenshots />} />
        <Route path="/roles" element={<Roles />} />
        <Route path="/managerList" element={<ManagerList />} />
        <Route path="/addroles" element={<Roles />} />
        <Route path="/editroles" element={<Roles />} />
        <Route path="/viewroles" element={<Roles />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/reports" element={<Reports />} />
      </Routes>
      {addManager?.modalShow && <AddManager />}
      <ToastContainer />
    </>
  );
}

export default App;
