import React from 'react'
import NavbarLayout from '../components/NavbarLayout'
import "../styles/settings.scss"
import { Tabs, TabsProps } from 'antd';
import General from '../components/Settings/General';
import Tracking from '../components/Settings/Tracking';
import TaskList from '../components/Settings/Tasklist';
const Settings = () => {


    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'General',
            children: <General />,
        },
        {
            key: '2',
            label: 'Tracking and Monitoring',
            children: <Tracking />,
        },

    ];


    const onChange = (key: string) => {
        console.log(key);
    };
    return (
        <>
            <NavbarLayout>
                <div className="data-container">
                    <div className='settings-page-wrapper'>
                        <h4>Settings</h4>
                        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
                    </div>
                </div>
            </NavbarLayout>
        </>
    )
}

export default Settings