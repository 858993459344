import React, { useState, useRef, useEffect } from 'react';
import NavbarLayout from '../components/NavbarLayout';
import { Container, Row, Col, Form, Button, Image } from 'react-bootstrap';
import '../styles/reports.scss';
import { Select } from 'antd';
import Calendar from '../components/CustomComponents/DatePicker/Calendar';
import { commonGetService, commonPostservice } from '../utils/properties';
import { showToast } from '../features/Toaster/toastslice';
import { useAppDispatch } from '../app/hooks';
import moment from 'moment';


interface UserData {
    employee_id: number,
    employee_name: string
}

interface ReportData {
    id: string,
    report_type: string
}
interface OptionObject {
    label: string,
    value: string,
    uId?: number | string,
    isChecked: boolean
}

interface ReportDetails {
    report_name: string,
    employee_name: string[],
    employee_id: number[],
    report_type: string,
    start_date: string,
    end_date: string,
    isDrop?: boolean
}

const Reports = () => {
    const [userDrop, setUserDrop] = useState<boolean>(false);
    const [reportDrop, setReportDrop] = useState<boolean>(false);
    const [userOptions, setUserOptions] = useState<OptionObject[]>([])
    const [reportOptions, setReportOptions] = useState<OptionObject[]>([])
    const [userNames, setUserNames] = useState<string[]>([]);
    const [reportNames, setReportNames] = useState<string[]>([]);
    const [reportName, setReportName] = useState<string>('');
    const [fromDate, setFromDate] = useState<Date | null>(null);
    const [toDate, setToDate] = useState<Date | null>(null);
    const [reportDetails, setReportDetails] = useState<ReportDetails[]>([])
    const [dropOptions, setDropOptions] = useState<string[]>(['CSV', 'PDF', 'Excel']);
    const [isDrop, setIsDrop] = useState<boolean>(false);
    const [userError, setUserError] = useState<boolean>(false);
    const [reportTypeError, setReportTypeError] = useState<boolean>(false);
    const [reportNameError, setReportNameError] = useState<boolean>(false);
    const [fromDateError, setFromDateError] = useState<boolean>(false);
    const [toDateError, setToDateError] = useState<boolean>(false);
    const [isGenerate, setIsGenerate] = useState<boolean>(false);


    const listRef = useRef<(HTMLButtonElement | null)[][]>([]);
    const targetRef = useRef<(HTMLButtonElement | null)[]>([]);

    const dispatch = useAppDispatch(); //Dispatch function



    useEffect(() => {
        window.addEventListener('click', handleDropOpen);
        getUsersList();
        getReportNameList();
        getReportList();

        return () => {
            window.removeEventListener('click', handleDropOpen);
        }
    }, [])

    const getUsersList = () => {
        commonGetService(`/api/1.0/report/users`).then(res => {
            if (res.status === 200) {
                let userData = res.data?.map((user: UserData) => {
                    return {
                        label: user?.employee_name,
                        value: user?.employee_id.toString(),
                        isChecked: false
                    }
                })

                userData?.length && userData?.unshift({
                    label: 'All',
                    value: '-1',
                    isChecked: false
                })

                setUserOptions(userData)
            }
        })
    }

    const getReportNameList = () => {
        commonGetService(`/api/1.0/report/type`).then(res => {
            if (res.status === 200) {
                let reportData = res.data?.map((report: ReportData) => {
                    return {
                        label: report?.report_type,
                        value: report?.id,
                        isChecked: false
                    }
                })

                reportData?.length && reportData?.unshift({
                    label: 'All',
                    value: '-1',
                    isChecked: false
                })

                setReportOptions(reportData);
            }
        })
    }

    const getReportList = () => {
        commonGetService(`/api/1.0/report`).then(res => {
            if (res.status === 200) {
                let reportList = res.data?.map((report: ReportDetails) => {
                    listRef.current.push([]);
                    return {
                        report_name: report?.report_name,
                        employee_name: report?.employee_name,
                        employee_id: report?.employee_id,
                        report_type: report?.report_type,
                        start_date: moment(new Date(report?.start_date)).format("DD-MMM-YYYY"),
                        end_date: moment(new Date(report?.end_date)).format("DD-MMM-YYYY"),
                        isDrop: false
                    }
                })
                setReportDetails(reportList);
            }
        })
    }

    const handleDropOpen = (e: MouseEvent) => {
        if (targetRef && e.target instanceof Node && !targetRef.current?.some(res => res?.contains(e.target as Node))) {
            setReportDetails((prevState) =>
                prevState.map((res) => { return { ...res, isDrop: false } })
            );
        }
    }

    const handleChange = (value: string[], name: string) => {
        if (name === 'user') {
            setUserOptions((prevState) => {
                prevState?.forEach((res, i, arr) => {
                    if (value.length && value[value.length - 1] == '-1') {
                        res.isChecked = true;
                    }
                    else {
                        if (value.length && value[value.length - 2] == '-1' && value[value.length - 1] == res.value) {
                            arr[0].isChecked = false;
                            res.isChecked = false;
                        }
                        else if (value.length && value[value.length - 2] == '-1' && res.value != '-1') {
                            res.isChecked = true;
                        }
                        else if (value.includes(res.value)) {
                            res.isChecked = true;
                        }
                        else {
                            res.isChecked = false;
                        }
                    }
                })
                return prevState;
            })

            setTimeout(() => {
                setUserNames((prevState) => {
                    if (value.length && value[value.length - 1] == '-1') {
                        return ['-1'];
                    }
                    else {
                        let newValues = userOptions?.filter(res => res.isChecked)?.map(res => res.value);
                        return newValues
                    }
                })
                setUserError(false);
            }, 0)
        }
        else {
            setReportOptions((prevState) => {
                prevState?.forEach((res, i, arr) => {
                    if (value.length && value[value.length - 1] == '-1') {
                        res.isChecked = true;
                    }
                    else {
                        if (value.length && value[value.length - 2] == '-1' && value[value.length - 1] == res.value) {
                            arr[0].isChecked = false;
                            res.isChecked = false;
                        }
                        else if (value.length && value[value.length - 2] == '-1' && res.value != '-1') {
                            res.isChecked = true;
                        }
                        else if (value.includes(res.value)) {
                            res.isChecked = true;
                        }
                        else {
                            res.isChecked = false;
                        }
                    }
                })
                return prevState;
            })

            setTimeout(() => {
                setReportNames((prevState) => {
                    if (value.length && value[value.length - 1] == '-1') {
                        return ['-1'];
                    }
                    else {
                        let newValues = reportOptions?.filter(res => res.isChecked)?.map(res => res.value);
                        return newValues
                    }
                })
                setReportTypeError(false);
            }, 0)
        }
    };

    const handleDateChange = (val: Date | null, name: string) => {
        if (name === 'fromDate') {
            setFromDate(val);
            setFromDateError(false);
        }
        else {
            setToDate(val);
            setToDateError(false);
        }
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>, ind: number, index: number) => {
        if (e.key === 'ArrowDown') {
            e.preventDefault();
            listRef.current[ind][index + 1]?.focus();
        } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            listRef.current[ind][index - 1]?.focus();
        } else if (e.key === 'Enter') {
            alert(`You selected Item ${index + 1}`);
        }
    };

    const handleValidate = () => {
        let isClear = true;
        if (userOptions?.every(res => !res.isChecked)) {
            setUserError(true);
            isClear = false;
        }
        if (reportOptions?.every(res => !res.isChecked)) {
            setReportTypeError(true);
            isClear = false;
        }
        if (reportName.trim() === "") {
            setReportNameError(true);
            isClear = false;
        }
        if (!fromDate) {
            setFromDateError(true);
            isClear = false;
        }
        if (!toDate) {
            setToDateError(true);
            isClear = false;
        }
        if (isClear) {
            return true;
        }
        else {
            return false;
        }
    }

    const handleGenerateReport = () => {
        if (handleValidate()) {
            let params = {
                "empIds": userOptions?.filter(res => res.isChecked && res.value !== '-1')?.map(res => Number(res.value)),
                "report_type": reportOptions?.filter(res => res.isChecked && res.value !== '-1')?.map(res => res.label),
                "report_name": reportName,
                "start_date": moment(fromDate).format("YYYY-MM-DD"),
                "end_date": moment(toDate).format("YYYY-MM-DD")
            }

            setIsGenerate(true);
            commonPostservice(`/api/1.0/report/generate`, params).then(res => {
                setIsGenerate(false);
                if (res.status === 200) {
                    getReportList();
                }
                else {
                    dispatch(
                        showToast({
                            message: "Something went wrong in API",
                            status: "retry",
                            visible: true,
                        })
                    );
                }
            })
        }
    }

    const handleDownloadDropdown = (i: number) => {
        setReportDetails((prevState) =>
            prevState.map((res, ind) =>
                i === ind ? { ...res, isDrop: !res.isDrop } : { ...res, isDrop: false }
            )
        );
    }

    const handleDownloadReport = (reportData: ReportDetails, format: string) => {
        let params = {
            "empIds": reportData?.employee_id,
            "report_type": [reportData?.report_type],
            "report_name": reportData?.report_name,
            "start_date": moment(reportData?.start_date).format("YYYY-MM-DD"),
            "end_date": moment(reportData?.end_date).format("YYYY-MM-DD"),
            "report_format": format === "PDF" ? "pdf" : format === "CSV" ? "csv" : "excel"
        }

        commonPostservice(`/api/1.0/report/download`, params).then(res => {
            if (res.status === 200) {
                let linkElement = document.createElement('a');
                linkElement.href = res?.data;
                document.body.appendChild(linkElement);
                linkElement.click();
                document.body.removeChild(linkElement);
            }
            else {
                dispatch(
                    showToast({
                        message: "Something went wrong in API",
                        status: "retry",
                        visible: true,
                    })
                );
            }
        })
    }

    return (
        <NavbarLayout>
            <Container fluid className='w-100 m-0 report-out-container'>
                <div className='report-in-container'>
                    <Row className='m-0'>
                        <h4 className='mb-0 p-0'>Reports</h4>
                    </Row>
                    <Row className='user-containr' gap={{ lg: 4 }}>
                        <Col lg='6' className='cus-col'>
                            <label htmlFor='user-select'>Select User</label>
                            <Select
                                mode="multiple"
                                style={{ width: '100%' }}
                                placeholder="Select User, Department etc.,"
                                onChange={(val) => { handleChange(val, 'user') }}
                                options={userOptions}
                                optionRender={(option) => (
                                    <Form.Check
                                        type='checkbox'
                                        id={`user-${option.data.uId}`}
                                        label={option.data.label}
                                        checked={option.data.isChecked}
                                        readOnly
                                    // onClick={e=>e.stopPropagation()}
                                    />
                                )}
                                value={userNames}
                                popupClassName='mod-drop'
                                className={`custom-dropdown ${userDrop ? 'drop-open' : ''}`}
                                showSearch={false}
                                suffixIcon={<img src="/images/dashboard/down_arrow.svg" />}
                                onDropdownVisibleChange={(isOpen) => { setUserDrop(isOpen) }}
                                id='user-select'
                            />
                            {userError && <p className='err-txt'>Select the User</p>}
                        </Col>
                        <Col lg='6' className='cus-col'>
                            <label htmlFor='report-select'>Report Type</label>
                            <Select
                                mode="multiple"
                                style={{ width: '100%' }}
                                placeholder="Select Report Type"
                                onChange={(val) => handleChange(val, 'report')}
                                options={reportOptions}
                                optionRender={(option) => (
                                    <Form.Check
                                        type='checkbox'
                                        id={`report-${option.data.uId}`}
                                        label={option.data.label}
                                        checked={option.data.isChecked}
                                        onClick={e => e.stopPropagation()}
                                    />
                                )}
                                value={reportNames}
                                popupClassName='mod-drop'
                                className={`custom-dropdown ${reportDrop ? 'drop-open' : ''}`}
                                showSearch={false}
                                suffixIcon={<img src="/images/dashboard/down_arrow.svg" />}
                                onDropdownVisibleChange={(isOpen) => { setReportDrop(isOpen) }}
                                id='report-select'
                            />
                            {reportTypeError && <p className='err-txt'>Select the Report</p>}
                        </Col>
                    </Row>
                    <Row className='report-containr' gap={{ lg: 4 }}>
                        <Col lg='6' className='cus-col'>
                            <Form.Label htmlFor='report-name'>Report Name</Form.Label>
                            <Form.Control type='text' name='reportName' id='report-name' placeholder='Enter Report Name' value={reportName}
                                onChange={(e) => { setReportName(e.target.value); setReportNameError(false); }} />
                            {reportNameError && <p className='err-txt'>Enter the report name</p>}
                        </Col>
                        <Col lg='6' className='d-flex flex-column cus-col mt-auto'>
                            <div>
                                <h6 className='label-text mb-0'>Report Date Range</h6>
                            </div>
                            <div className='d-flex flex-row mt-2 w-100 justify-content-between align-items-center'>
                                <Calendar
                                    selectedDate={fromDate}
                                    handleDateChange={handleDateChange}
                                    calTitle='fromDate'
                                    isError={fromDateError}
                                />
                                <div className='to-head'>To</div>
                                <Calendar
                                    selectedDate={toDate}
                                    handleDateChange={handleDateChange}
                                    calTitle='toDate'
                                    isError={toDateError}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className='gen-button'>
                        <Col>
                            <Button onClick={() => handleGenerateReport()} disabled={isGenerate}>Generate report</Button>
                        </Col>
                    </Row>
                    <Row className='table-header'>
                        <Col>
                            <h6>Recently Generated Reports <span>{`(${reportDetails?.length} Report)`}</span></h6>
                        </Col>
                    </Row>
                    <Row className='table-container'>
                        <Col>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Report Name</th>
                                        <th>User</th>
                                        <th>Report Type</th>
                                        <th>From Date</th>
                                        <th>To Date</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {reportDetails.length ?
                                        reportDetails?.map((res, ind) => (
                                            <tr>
                                                <td><div>{res?.report_name}</div></td>
                                                <td>
                                                    <div className='d-flex flex-row report-type'>
                                                        {res?.employee_name?.filter((_, i) => i < 2)?.map((reportName, ind) => (
                                                            <div key={ind}>{reportName}</div>
                                                        ))}
                                                        {res?.employee_name.length > 2 && <div key={"+1"}>{`+${res?.employee_name.length - 2}`}</div>}
                                                    </div>
                                                </td>
                                                <td><div>{res?.report_type}</div></td>
                                                <td><div>{res?.start_date}</div></td>
                                                <td><div>{res?.end_date}</div></td>
                                                <td>
                                                    <div className='format-cont'>
                                                        <div className='d-flex flex-row justify-content-end'>
                                                            <Button><Image src='images/reports/eye_icon.svg' /></Button>
                                                            <Button className='ms-2' ref={(el: HTMLButtonElement) => (targetRef.current[ind] = el)} onClick={() => {
                                                                handleDownloadDropdown(ind);
                                                            }}>
                                                                <Image src='images/reports/download_icon.svg' />
                                                            </Button>
                                                        </div>
                                                        {res?.isDrop &&
                                                            <div className='cus-drop-menu'>
                                                                <div className='d-flex flex-column'>
                                                                    {dropOptions?.map((item, index) => (
                                                                        <Button
                                                                            key={index}
                                                                            ref={(el: HTMLButtonElement) => (listRef.current[ind][index] = el)} // Keep a reference to each item
                                                                            onKeyDown={(e) => handleKeyDown(e, ind, index)}
                                                                            autoFocus={index === 0}
                                                                            onClick={() => handleDownloadReport(res, item)}
                                                                        >
                                                                            <Image src='images/reports/csv_icon.svg' alt='csv' />
                                                                            <span>{item}</span>
                                                                        </Button>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        )) :
                                        <tr>
                                            <td>-</td>
                                            <td>-</td>
                                            <td>-</td>
                                            <td>-</td>
                                            <td>-</td>
                                            <td></td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </div>
            </Container>
        </NavbarLayout >
    );
};

export default Reports;